// src/components/Footer.js
import React from 'react';
import '../styles/Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="widgets_wrapper">
                <div className="container">
                    <div className="column one-third">
                        <div className="widget_text">
                            <hr className="no_line" style={{ margin: "0 auto 40px auto" }} />
                            <p className="themecolor">Call us if you need any help</p>
                            <div className="contact" style={{ color: "#fff" }}>
                                +380(67)770 15 50<br />
                                <a href="mailto:book@travel-on.com.ua" style={{ color: "#fff", textDecoration: "none" }}>
                                    book@travel-on.com.ua
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="column one-third">
                        <div className="widget_text">
                            {/* Здесь можно добавить содержимое второго столбца */}
                        </div>
                    </div>
                    <div className="column one-third">
                        <div className="widget_text">
                            {/* Здесь можно добавить содержимое третьего столбца */}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
