// src/components/Home.js
import React from 'react';
import '../styles/Home.css';
import backgroundImage from '../assets/images/cottage-home-slider-bg.jpg';

const Home = () => {
    const titleStyle= {
        fontFamily: 'Barlow Condensed',
        fontWeight: 500,
        fontSize:'2.5rem',
        letterspacing:'1px',
        color: 'rgb(196,208,80)',
        WebkitTextStrokeColor: 'white',
        WebkitTextStrokeWidth: '1px',
    };
    return (
        <div className="slider">
            <div className="slider-loader">
                <div className="dot1"></div>
                <div className="dot2"></div>
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
            <div
                className="slider-background"
                style={{ backgroundImage: `url(${backgroundImage})` }}
            >
                <div className="slider-content" style={titleStyle}>
                    <h1>We are <br /> very glad <br /> to see you here!</h1>
                </div>
            </div>
        </div>
    );
};

export default Home;
