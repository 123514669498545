// src/components/Tickets.js
import React from 'react';
import '../styles/Tickets.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faLightbulb, faSuitcaseRolling, faClock, faPhone, faHome, faEnvelope, faPencil, faKeyboard } from '@fortawesome/free-solid-svg-icons';
import qualityImg from '../assets/images/logo_quality_garantee.jpg';
import saveTimeImg from '../assets/images/save_time.jpg';
import answerImg from '../assets/images/answer-1.jpg';
import coworkersImg from '../assets/images/coworkers.jpg';
import backgroundImage from '../assets/images/airplane_inside.jpg';
import {TicketItem} from "./TicketItem";
import '../styles/Fonts.css';
const Tickets = () => {
    const titleStyle= {
        fontFamily: 'Barlow Condensed',
        fontWeight: 500,
        fontSize:'5rem',
        letterspacing:'1px',
        color: 'rgb(38,33,97)',
        WebkitTextStrokeColor: 'white',
        WebkitTextStrokeWidth: '1px',
    };
    return (
        <div id="Content">
            {/* Блок с фоном и заголовком */}
            <div
                className="tickets-header"
                style={{ backgroundImage: `url(${backgroundImage})` }}
            >
                <div className="overlay">
                    <div className="slider-content">
                        <h1 style={titleStyle}>
                            For Crewing company,<br /> Seamen agency and <br /> crew staff <br /> We are offering:
                        </h1>
                    </div>
                </div>
            </div>

            {/* Список услуг с иконками */}
            <div className="feature-list">
                <ul>
                    <TicketItem icon={faCheck}>Seamen’s airfares for all biggest Airlines</TicketItem>
                    <TicketItem icon={faCheck}>Special fare for seamen who fly to/from Ukraine and Worldwide includes</TicketItem>
                    <TicketItem icon={faLightbulb}>Search and selection the best connection</TicketItem>
                    <TicketItem icon={faCheck}>Check of all border and transit rules for safety trip</TicketItem>
                    <TicketItem icon={faCheck}>Any form of payment and credit limit for regular clients</TicketItem>
                    <TicketItem icon={faCheck}>Various airline ticket price fares (Seamens, group, corporative, tour, etc.)</TicketItem>
                    <TicketItem icon={faSuitcaseRolling}>Online check-in; additional baggage</TicketItem>
                    <TicketItem icon={faClock}>Client Services 24/7 for the whole voyage duration</TicketItem>
                    <TicketItem icon={faPhone}>Free consultation services for all professionally related questions</TicketItem>
                    <TicketItem icon={faHome}>Hotel searching and booking around the world</TicketItem>
                    <TicketItem icon={faEnvelope}>Consulate document preparation services</TicketItem>
                    <TicketItem icon={faPencil}>Form/Questionnaire filling, document translation, appointment booking services</TicketItem>
                    <TicketItem icon={faKeyboard}>Electronic Visa Form filling Services</TicketItem>
                    <TicketItem icon={faLightbulb}>And many other Services!</TicketItem>
                </ul>
            </div>

            {/* Блок с предложением о сотрудничестве */}
            <div className="cooperation-section">
                <h2>By offering our cooperation:</h2>
                <div className="cooperation-icons">
                    <div className="icon-box">
                        <img src={qualityImg} alt="Quality Guarantee" />
                        <h4>We guarantee</h4>
                        <p>professional approach to all services mentioned above</p>
                    </div>
                    <div className="icon-box">
                        <img src={saveTimeImg} alt="Save Time" />
                        <h4>We save</h4>
                        <p>your time and finances!</p>
                    </div>
                    <div className="icon-box">
                        <img src={answerImg} alt="Answer Questions" />
                        <h4>We answer</h4>
                        <p>all questions 24/7!</p>
                    </div>
                    <div className="icon-box">
                        <img src={coworkersImg} alt="Coworkers" />
                        <h4>For your confidence,</h4>
                        <p>we can provide a recommendation letter from our Partner!</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tickets;
