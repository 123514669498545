// src/components/About.js
import React from 'react';
import '../styles/About.css';
import cottageImage from '../assets/images/cottage-about-main-bg.jpg';
import thailandImage from '../assets/images/border-between-thailand-and-laos-1_7505.jpg';

const About = () => {
    const titleStyle= {
        fontFamily: 'Barlow Condensed',
        fontWeight: 500,
        fontSize:'2.0rem',
        letterspacing:'1px',
        color: '#c4d050',
        WebkitTextStrokeColor: 'white',
        WebkitTextStrokeWidth: '1px',
    };
    return (
        <div className="about-section">
            {/* Блок с полноэкранным фоном и заголовком */}
            <div
                className="about-header"
                style={{ backgroundImage: `url(${cottageImage})` }}
            >
                <div className="about-header-content" style={titleStyle}>
                    <h1>Welcome!<br />Few words<br />about us</h1>
                </div>
            </div>

            {/* Секция с описанием услуг */}
            <div className="about-content">
                <div className="about-content-left">
                    <img
                        src={thailandImage}
                        alt="border-between-thailand-and-laos"
                        className="about-image"
                    />
                </div>
                <div className="about-content-right">
                    <h4>Detailed description of the services provided by TRAVEL-ON LLC</h4>
                    <p>
                        The main specialization of the agency is a comprehensive service for legal entities in the field of business travel and individuals, including: the implementation of international and domestic air transportation (providing information, booking and selling air tickets), related (booking hotel services, insurance, etc.), travel services.
                    </p>
                </div>
            </div>

            {/* Секция с перечислением услуг */}
            <div className="about-services">
                <h4>LLC "TRAVEL-ON" offers the following services:</h4>
                <ul>
                    <li>Booking and sale of air tickets for domestic flights;</li>
                    <li>Booking and sale of international air tickets;</li>
                    <li>Sale of train tickets;</li>
                    <li>Hotel booking in Ukraine and abroad;</li>
                    <li>Tourist services;</li>
                    <li>Business tourism services (meetings, conferences, exhibitions);</li>
                    <li>Health insurance;</li>
                </ul>
            </div>

            {/* Секция с дополнительной информацией */}
            <div className="about-additional-info">
                <h4>ADDITIONAL INFORMATION</h4>
                <ul>
                    <li>All orders are accepted in any way convenient for the Client: phone, e-mail and others;</li>
                    <li>All requests that come from your company, depending on the volume and complexity of the order, are processed within 30 (thirty) minutes;</li>
                    <li>Support of corporate clients 24/7 by personal managers - responsible for customer service;</li>
                    <li>Concluding tripartite agreements with airlines, obtaining special fares for popular destinations based on an analysis of the volumes and needs of the Client;</li>
                    <li>On-line registration with the provision of boarding passes to the Client;</li>
                    <li>When returning purchased services, penalties are applied only if it is provided for by the rules of the applied tariffs of the service providers. No additional service fees are charged;</li>
                    <li>Provision of management reports in a form convenient for the client;</li>
                    <li>Cost optimization, calculation of cost savings;</li>
                    <li>An individual approach to each client, taking into account their wishes and specifics of work;</li>
                    <li>15 years of experience of employees.</li>
                </ul>
            </div>
        </div>
    );
};

export default About;
