import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/logo_word.svg';
import '../styles/Menu.css';
import {Container, Nav, Navbar} from "react-bootstrap";

const Menu = () => {
    return (
        <Navbar expand="lg" className="bg-body-tertiary" sticky="top">
            <Container>
                <Navbar.Brand href={'/'}>
                    <div className="logo">
                        <img className="logo-img" src={logo} alt="logo_word"/>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav" className={'navbar__inner justify-content-end'}>
                        <Nav className="navbar__items">
                            <NavLink className={'nav-link'} to="/">Home</NavLink>
                            <NavLink className={'nav-link'} to="/tickets">Tickets</NavLink>
                            <NavLink className={'nav-link'} to="/about">About</NavLink>
                            <NavLink className={'nav-link'} to="/contact">Contact</NavLink>
                        </Nav>
                    </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Menu;
