import React from 'react';
import '../styles/Contact.css';
import backgroundImage from '../assets/images/cottage-contact-header-bg.jpg';
import addressImg from '../assets/images/address.jpg';

const Contact = () => {
    const titleStyle= {
        fontFamily: 'Barlow Condensed',
        fontWeight: 500,
        fontSize:'2.0rem',
        letterspacing:'1px',
        color: 'rgb(196,208,80)',
        WebkitTextStrokeColor: 'white',
        WebkitTextStrokeWidth: '1px',
    };
    return (
        <div id="Content">
            {/* Блок с фоном и заголовком */}
            <div className="contact-header">
                <div
                    className="slider-background"
                    style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <div className="slider-content" style={titleStyle}>
                        <h1>Contact<br />Our address<br />info</h1>
                    </div>
                </div>
            </div>

            {/* Блок с адресом */}
            <div className="address-section">
                <div className="address-content">
                    <div className="address-image">
                        <img src={addressImg} alt="Address" style={{width:"100%"}}/>
                    </div>
                    <div className="address-info">
                        <h2 className="themecolor">Address:</h2>
                        <p>23 Sofiyevskaya Str,<br />Odessa, Ukraine</p>
                        <p><i className="icon-call"></i> +380 67 770 15 50</p>
                        <p><i className="icon-mail"></i> book@travel-on.com.ua</p>
                    </div>
                </div>
            </div>

            {/* Форма обратной связи */}
            <div className="form-section">
                <h3>Fill the form</h3>
                <form className="contact-form">
                    <div className="form-group">
                        <input type="text" name="your-name" placeholder="YOUR NAME" required />
                        <input type="email" name="your-email" placeholder="EMAIL ADDRESS" required />
                    </div>
                    <div className="form-group">
                        <input type="text" name="your-message" placeholder="MESSAGE" required />
                    </div>
                    <button type="submit" className="submit-btn">SEND MESSAGE</button>
                </form>
            </div>

            {/* Google Map */}
            <div className="map-section">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2746.946509978341!2d30.73301986857354!3d46.489371583968584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c631be584863af%3A0xdf28234bb9249ca8!2z0YPQuy4g0KHQvtGE0LjQtdCy0YHQutCw0Y8sIDIzLCDQntC00LXRgdGB0LAsINCe0LTQtdGB0YHQutCw0Y8g0L7QsdC70LDRgdGC0YwsIDY1MDAw!5e0!3m2!1sru!2sua!4v1627981016846!5m2!1sru!2sua"
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe>
            </div>
        </div>
    );
};

export default Contact;
